import { PaymentRepository } from '../repository/payment.repo';
import {
  AddPaymentResponsePayload,
  AddPaymentSchema,
  FetchPaymentGatewaysPayloadResponsePayload,
  InitializePaymentRequestPayload,
  InitializePaymentResponsePayload,
} from '../payment.type';

export class PaymentService {
  constructor(private readonly paymentRepo: PaymentRepository) {}

  async addPayment(payload: AddPaymentSchema): Promise<AddPaymentResponsePayload> {
    const response = await this.paymentRepo.addPayment(payload);
    return response;
  }

  async fetchPaymentGateways(): Promise<FetchPaymentGatewaysPayloadResponsePayload> {
    const response = await this.paymentRepo.fetchPaymentGateways();
    return response;
  }

  async initializePayment(payload: InitializePaymentRequestPayload): Promise<InitializePaymentResponsePayload> {
    const response = await this.paymentRepo.initializePayment(payload);
    return response;
  }
}
