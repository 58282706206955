import { Suspense, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Spinner from './components/spinner/spinner';
import { authRoutes, sidebarRoutes } from './commons/types/routes';
import { withFaroRouterInstrumentation } from '@grafana/faro-react';

const AuthenticationLayout = lazy(() => import('./layout/authentication/authentication_layout'));
const Login = lazy(() => import('./pages/authentication'));
const OTP = lazy(() => import('./pages/authentication/components/otp'));
const PrivatePolicy = lazy(() => import('./pages/privatePolicy/index'));

const MainLayout = lazy(() => import('./layout/dashboard'));
const Dashboard = lazy(() => import('./pages/analytics/dashboard'));
const MapPage = lazy(() => import('./pages/map'));
const Orders = lazy(() => import('./pages/orders/orders'));
const Vendor = lazy(() => import('./pages/vendor/vendor'));
const AddProduct = lazy(() => import('./pages/vendor/components/addProduct'));
const Rider = lazy(() => import('./pages/rider/rider'));
const FleetOwner = lazy(() => import('./pages/fleetOwner/fleet_owner'));
const Adverts = lazy(() => import('./pages/adverts/adverts'));
const Coupon = lazy(() => import('./pages/coupon/coupon'));
const AdminCommission = lazy(() => import('./pages/adminCommission'));
const Accounting = lazy(() => import('./pages/accounting/accounting'));
const GeoFence = lazy(() => import('./pages/geoFence/geofence'));
const SubAdmin = lazy(() => import('./pages/subAdmin/subAdmin'));
const Settings = lazy(() => import('./pages/settings/settings'));
const OrderDetails = lazy(() => import('./pages/orders/components/orderDetails/order_details'));
const VendorDetails = lazy(() => import('./pages/vendor/components/vendorDetails/vendor_details'));
const RiderDetails = lazy(() => import('./pages/rider/components/riderDetails/rider_details'));
const FleetOwnerDetails = lazy(() => import('./pages/fleetOwner/components/fleetOwnerDetails/fleet_owner_details'));
const AddNewCoupon = lazy(() => import('./pages/coupon/components/addNewCoupon/add_new_coupon'));
const EditGeoFence = lazy(() => import('./pages/geoFence/components/edit/edit'));
const AddNew = lazy(() => import('./pages/geoFence/components/addNew/add_new'));
const AddNewCommission = lazy(() => import('./pages/adminCommission/components/addNewCommission/add_new_commission'));
const EditNewCommission = lazy(() => import('./pages/adminCommission/components/editCommission/index'));
const PaymentPage = lazy(() => import('./pages/payment'));

const ErrorPage = lazy(() => import('./pages/error/index'));

export const router = createBrowserRouter([
  {
    path: authRoutes.login,
    element: (
      <Suspense fallback={<Spinner message="Building page..." />}>
        <AuthenticationLayout />
      </Suspense>
    ),
    children: [
      {
        path: '',
        element: <Login />,
      },
      {
        path: authRoutes.loginOTP,
        element: <OTP />,
      },
    ],
  },
  {
    path: '/private-policy',
    element: (
      <Suspense fallback={<Spinner message="Building page..." />}>
        <PrivatePolicy />
      </Suspense>
    ),
  },
  {
    path: sidebarRoutes.dashboard,
    element: (
      <Suspense fallback={<Spinner message="Building page..." />}>
        <MainLayout />
      </Suspense>
    ),
    children: [
      {
        path: sidebarRoutes.dashboard,
        element: <Dashboard />,
      },
      {
        path: sidebarRoutes.map,
        element: <MapPage />,
      },
      {
        path: sidebarRoutes.orders,
        element: <Orders />,
      },
      {
        path: sidebarRoutes.viewOrder,
        element: <OrderDetails />,
      },
      {
        path: sidebarRoutes.vendors,
        element: <Vendor />,
      },
      {
        path: sidebarRoutes.viewVendor,
        element: <VendorDetails />,
      },
      {
        path: sidebarRoutes.addProduct,
        element: <AddProduct />,
      },
      {
        path: sidebarRoutes.riders,
        element: <Rider />,
      },
      {
        path: sidebarRoutes.viewRider,
        element: <RiderDetails />,
      },
      {
        path: sidebarRoutes.fleetOwners,
        element: <FleetOwner />,
      },
      {
        path: sidebarRoutes.viewFleetOwner,
        element: <FleetOwnerDetails />,
      },
      {
        path: sidebarRoutes.adverts,
        element: <Adverts />,
      },
      {
        path: sidebarRoutes.coupon,
        element: <Coupon />,
      },
      {
        path: sidebarRoutes.addCoupon,
        element: <AddNewCoupon />,
      },
      {
        path: sidebarRoutes.commission,
        element: <AdminCommission />,
      },
      {
        path: sidebarRoutes.addCommission,
        element: <AddNewCommission />,
      },
      {
        path: sidebarRoutes.editCommission,
        element: <EditNewCommission />,
      },
      {
        path: sidebarRoutes.accounting,
        element: <Accounting />,
      },
      {
        path: sidebarRoutes.geoFence,
        element: <GeoFence />,
      },
      {
        path: sidebarRoutes.editGeoFence,
        element: <EditGeoFence />,
      },
      {
        path: sidebarRoutes.addGeoFence,
        element: <AddNew />,
      },
      {
        path: sidebarRoutes.subAdmin,
        element: <SubAdmin />,
      },
      {
        path: sidebarRoutes.settings,
        element: <Settings />,
      },
      {
        path: sidebarRoutes.payment,
        element: <PaymentPage />,
      },
    ],
  },
  {
    path: '*',
    element: (
      <Suspense fallback={<Spinner message="Building page..." />}>
        <ErrorPage />
      </Suspense>
    ),
  },
]);

export const browserRouter = withFaroRouterInstrumentation(router);
