import { Api } from '../../../utils';
import {
  AddPaymentResponsePayload,
  AddPaymentSchema,
  FetchPaymentGatewaysPayloadResponsePayload,
  InitializePaymentRequestPayload,
  InitializePaymentResponsePayload,
} from '../payment.type';

export class PaymentRepository {
  async addPayment(payload: AddPaymentSchema): Promise<AddPaymentResponsePayload> {
    const response = await Api.post<AddPaymentResponsePayload, AddPaymentResponsePayload>(
      'v1/admin/payment-gateway/add-gateway',
      payload
    );
    return response;
  }

  async fetchPaymentGateways(): Promise<FetchPaymentGatewaysPayloadResponsePayload> {
    const response = await Api.get<
      FetchPaymentGatewaysPayloadResponsePayload,
      FetchPaymentGatewaysPayloadResponsePayload
    >('v1/admin/payment-gateway/fetch-all-gateways');
    return response;
  }

  async initializePayment(payload: InitializePaymentRequestPayload): Promise<InitializePaymentResponsePayload> {
    const response = await Api.post<InitializePaymentResponsePayload, InitializePaymentResponsePayload>(
      '/a/v1/payment/initialize',
      payload
    );
    return response;
  }
}
