import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';

import { matchRoutes } from 'react-router-dom';
import {
  initializeFaro,
  createReactRouterV6DataOptions,
  ReactIntegration,
  getWebInstrumentations,
} from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

import './assets/css/index.css';
import { browserRouter } from './App';
import reportWebVitals from './reportWebVitals';
import theme from './chakraui.config';
import { store } from './redux/app/store';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallBackUI } from './components/errors/error_fallback_UI';

initializeFaro({
  url: 'https://faro-collector-prod-eu-west-2.grafana.net/collect/a44aa8bb38101e8b6bb2944c12b89e14',
  app: {
    name: 'foodbag.queen',
    version: '0.10.11',
    environment: process.env?.REACT_APP_ENVIRONMENT ?? 'staging',
  },

  instrumentations: [
    // Mandatory, omits default instrumentations otherwise.
    ...getWebInstrumentations(),

    // Tracing package to get end-to-end visibility for HTTP requests.
    new TracingInstrumentation(),

    // React integration for React applications.
    new ReactIntegration({
      router: createReactRouterV6DataOptions({
        matchRoutes,
      }),
    }),
  ],
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <ErrorBoundary
          FallbackComponent={ErrorFallBackUI}
          onError={() => console.log('An error occurred!')}
          onReset={() => {
            window.location.reload();
          }}
        >
          <RouterProvider router={browserRouter} />
        </ErrorBoundary>
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
