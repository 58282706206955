import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { InitializePaymentRequestPayload, InitializePaymentState } from '../payment.type';
import { HttpStatus } from '../../../commons';
import { PaymentRepository } from '../repository/payment.repo';
import { PaymentService } from '../service/payment.service';

const NAMESPACE = 'INITIALIZE_PAYMENT';

const initialState: InitializePaymentState = {
  status: HttpStatus.IDLE,
  message: undefined,
  payload: undefined,
};

export const initializePayment = createAsyncThunk(
  `${NAMESPACE}/post`,
  async (payload: InitializePaymentRequestPayload) => {
    const paymentRepo = new PaymentRepository();
    const paymentService = new PaymentService(paymentRepo);
    const response = await paymentService.initializePayment(payload);
    return response;
  }
);

const initializePaymentSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetInitializePaymentStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initializePayment.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(initializePayment.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.payload = payload;
    });
    builder.addCase(initializePayment.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetInitializePaymentStatus } = initializePaymentSlice.actions;

export default initializePaymentSlice.reducer;
