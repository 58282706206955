export const authRoutes = {
  login: '/',
  loginOTP: '/login-otp',
} as const;

export const sidebarRoutes = {
  dashboard: '/dashboard',
  map: 'dashboard/map',
  orders: '/dashboard/orders',
  viewOrder: '/dashboard/orders/:id',
  vendors: '/dashboard/vendors',
  viewVendor: '/dashboard/vendors/:id',
  addProduct: '/dashboard/vendors/:id/add-product',
  riders: '/dashboard/riders',
  viewRider: '/dashboard/riders/:id',
  fleetOwners: '/dashboard/fleet-owners',
  viewFleetOwner: '/dashboard/fleet-owners/:id',
  adverts: '/dashboard/adverts',
  coupon: '/dashboard/coupon',
  addCoupon: '/dashboard/coupon/add-new',
  commission: '/dashboard/commission',
  addCommission: '/dashboard/commission/add-new',
  editCommission: '/dashboard/commission/edit/:id',
  accounting: '/dashboard/accounting',
  geoFence: '/dashboard/geo-fence',
  editGeoFence: '/dashboard/geo-fence/edit/:id',
  addGeoFence: '/dashboard/geo-fence/add-new',
  subAdmin: 'dashboard/sub-admin',
  settings: '/dashboard/settings',
  payment: '/dashboard/payment',
} as const;

export type AuthRoutes = typeof authRoutes[keyof typeof authRoutes];
export type SidebarRoutes = typeof sidebarRoutes[keyof typeof sidebarRoutes];
